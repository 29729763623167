import { Icon } from '@iconify/react/dist/iconify.js';
import { Button, cn, ScrollShadow, Spacer } from '@nextui-org/react';
import { Outlet } from '@tanstack/react-router';
import { useCallback, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import HachiumIcon from '../../lib/icons/HachiumIcon';
import HachiumIconText from '../../lib/icons/HachiumIconText';
import UserInfo from '../common/UserInfo';
import Sidebar from '../features/sidebar/Sidebar';
import SidebarFooter from '../features/sidebar/SidebarFooter';
import { sectionItemsWithTeams } from '../features/sidebar/SidebarItems';

const Layout = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const isMobile = useMediaQuery('(max-width: 768px)');

  const isCompact = isCollapsed || isMobile;

  const onToggle = useCallback(() => {
    setIsCollapsed((prev) => !prev);
  }, []);

  return (
    <div className="flex h-dvh w-full">
      <div
        className={cn(
          'relative flex h-full w-72 flex-col !border-r-small border-divider p-6 transition-width',
          {
            'w-16 items-center px-2 py-6': isCompact,
          },
        )}
      >
        <div
          className={cn('flex items-center gap-3 px-3', {
            'justify-center gap-0': isCompact,
          })}
        >
          <span
            className={cn('text-small font-bold uppercase opacity-100', {
              'w-0 opacity-0': isCompact,
            })}
          >
            <HachiumIconText />
          </span>
          <HachiumIcon />
        </div>
        <Spacer y={8} />
        <UserInfo isCompact={isCompact} />
        <ScrollShadow className="-mr-6 h-full max-h-full py-6 pr-6">
          <Sidebar
            defaultSelectedKey="home"
            isCompact={isCompact}
            items={sectionItemsWithTeams}
          />
        </ScrollShadow>
        <Spacer y={2} />
        <SidebarFooter isCompact={isCompact} />
      </div>
      <div className="w-full flex-1 flex-col p-4">
        <header className="flex items-center gap-3 rounded-medium border-small border-divider p-4">
          <Button isIconOnly size="sm" variant="light" onPress={onToggle}>
            <Icon
              className="text-default-500"
              height={24}
              icon="solar:sidebar-minimalistic-outline"
              width={24}
            />
          </Button>
          <h2 className="text-medium font-medium text-default-700">Overview</h2>
        </header>
        <main className="mt-4 h-full w-full overflow-visible">
          <div className="flex h-[90%] w-full flex-col gap-4 rounded-medium border-small border-divider">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

export default Layout;
