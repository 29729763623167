import { Icon } from '@iconify/react';
import type { Selection, SortDescriptor } from '@nextui-org/react';
import {
  Button,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
} from '@nextui-org/react';
import { SearchIcon } from '@nextui-org/shared-icons';

import SelectedActions from '../../../common/SelectedActions';
import ColumnSelector from '../ColumnSelector/ColumnSelector';

type Props = {
  headerColumns: { name: string; uid: string }[];
  filterSelectedKeys: Selection;
  sortDescriptor: SortDescriptor;
  setSortDescriptor: (value: SortDescriptor) => void;
};

const TableTopContent = ({
  headerColumns,
  filterSelectedKeys,
  sortDescriptor,
  setSortDescriptor,
}: Props) => (
  <div className="flex items-center gap-4 overflow-auto px-[6px] py-[4px]">
    <div className="flex items-center gap-3">
      <div className="flex items-center gap-4">
        <Input
          aria-label="Search"
          className="min-w-[200px]"
          endContent={<SearchIcon className="text-default-400" width={16} />}
          placeholder="Search"
          size="sm"
        />
        <div>
          <Dropdown>
            <DropdownTrigger>
              <Button
                className="bg-default-100 text-default-800"
                size="sm"
                startContent={
                  <Icon
                    className="text-default-400"
                    icon="solar:sort-linear"
                    width={16}
                  />
                }
              >
                Sort
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Sort"
              items={headerColumns.filter(
                (c) => !['actions', 'teams'].includes(c.uid),
              )}
            >
              {(item) => (
                <DropdownItem
                  key={item.uid}
                  onPress={() => {
                    setSortDescriptor({
                      column: item.uid,
                      direction:
                        sortDescriptor.direction === 'ascending'
                          ? 'descending'
                          : 'ascending',
                    });
                  }}
                >
                  {item.name}
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        </div>
        <ColumnSelector columns={headerColumns} />
      </div>

      <Divider className="h-5" orientation="vertical" />

      <div className="whitespace-nowrap text-sm text-default-800">
        {filterSelectedKeys === 'all'
          ? 'All items selected'
          : `${filterSelectedKeys.size} Selected`}
      </div>

      <SelectedActions filterSelectedKeys={filterSelectedKeys} />
    </div>
  </div>
);

export default TableTopContent;
