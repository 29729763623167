import { Avatar } from '@nextui-org/react';
import { cn } from '@nextui-org/react';

type Props = {
  isCompact: boolean;
}

const UserInfo = ({ isCompact }: Props) => (
    <div className="flex items-center gap-3 px-3">
      <Avatar
        isBordered
        className="flex-none"
        size="sm"
        src="https://i.pravatar.cc/150?u=a04258114e29026708c"
      />
      <div className={cn('flex max-w-full flex-col', { hidden: isCompact })}>
        <p className="truncate text-small font-medium text-default-600">
          John Doe
        </p>
        <p className="truncate text-tiny text-default-400">Product Designer</p>
      </div>
    </div>
  );

export default UserInfo;
