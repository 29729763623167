import { Icon } from '@iconify/react';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react';

type Props = {
  columns: { name: string; uid: string }[];
}

const ColumnSelector = ({ columns }: Props) => (
    <Dropdown closeOnSelect={false}>
      <DropdownTrigger>
        <Button
          className="bg-default-100 text-default-800"
          size="sm"
          startContent={
            <Icon
              className="text-default-400"
              icon="solar:sort-horizontal-linear"
              width={16}
            />
          }
        >
          Columns
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        disallowEmptySelection
        aria-label="Columns"
        items={columns.filter((c) => !['actions'].includes(c.uid))}
        selectionMode="multiple"
      >
        {(item) => <DropdownItem key={item.uid}>{item.name}</DropdownItem>}
      </DropdownMenu>
    </Dropdown>
  );

export default ColumnSelector;
