import type { SidebarItemProps } from '../Sidebar/Sidebar';
import defaultBrandItems from './BrandItems';
import defaultItems from './DefaultItems';
import nestedItems from './NestedItems';
import paymentItems from './PaymentItems';
import sectionItems from './SectionItems';
import teamItems from './TeamItems';

export const items: SidebarItemProps[] = defaultItems;
export const sectionItemsWithTeams: SidebarItemProps[] = [
  ...sectionItems,
  ...teamItems,
];
export const brandItems: SidebarItemProps[] = defaultBrandItems;
export const sectionLongList: SidebarItemProps[] = [
  ...sectionItems,
  ...paymentItems,
  ...teamItems,
];
export const sectionNestedItems: SidebarItemProps[] = nestedItems;
