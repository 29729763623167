import { Icon } from '@iconify/react/dist/iconify.js';
import { Button } from '@nextui-org/react';

type Props = {
  onOpenVariableCreateModal: () => void;
};

const TopBar = ({ onOpenVariableCreateModal }: Props) => (
  <div className="flex justify-between items-center mb-4">
    <h1 className="text-2xl font-bold">Variables</h1>
    <Button
      onPress={onOpenVariableCreateModal}
      endContent={<Icon icon="solar:add-circle-bold" width={20} />}
    >
      Add Variable
    </Button>
  </div>
);

export default TopBar;
