import { cn } from '@nextui-org/react';

import SidebarFooterItem from '../SidebarFooterItem';

type Props = {
  isCompact: boolean;
}

const SidebarFooter = ({ isCompact }: Props) => {
  const footerItems = [
    {
      tooltipContent: 'Help & Feedback',
      icon: 'solar:info-circle-line-duotone',
      text: 'Help & Information',
    },
    {
      tooltipContent: 'Log Out',
      icon: 'solar:minus-circle-line-duotone',
      text: 'Log Out',
    },
  ];

  return (
    <div
      className={cn('mt-auto flex flex-col', {
        'items-center': isCompact,
      })}
    >
      {footerItems.map((item) => (
        <SidebarFooterItem
          key={item.text}
          isCompact={isCompact}
          tooltipContent={item.tooltipContent}
          icon={item.icon}
          text={item.text}
        />
      ))}
    </div>
  );
};

export default SidebarFooter;
