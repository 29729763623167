import { Chip } from '@nextui-org/react';
import { useNavigate } from '@tanstack/react-router';
import type { Key } from 'react';

import type { Variable } from '../../../../entities/variable';
import DeleteFilledIcon from '../../../../lib/icons/DeleteFilledIcon';
import EditLinearIcon from '../../../../lib/icons/EditLinearIcon';
import EyeFilledIcon from '../../../../lib/icons/EyeFilledIcon';
import CopyText from '../../../common/CopyText';

type Props = {
  variable: Variable;
  columnKey: keyof Variable | Key;
};

const TableCellRenderer = ({ variable, columnKey }: Props) => {
  const navigate = useNavigate();

  const cellValue = variable[columnKey as keyof Variable];

  switch (columnKey) {
    case 'variable_name':
      return <CopyText>{cellValue.toString()}</CopyText>;
    case 'facets':
      return (
        <div className="float-start flex gap-1">
          {variable.facets.map((facet: string, index: number) => {
            if (index < 3) {
              return (
                <Chip
                  key={facet}
                  className="rounded-xl bg-default-100 px-[6px] capitalize text-default-800"
                  size="sm"
                  variant="flat"
                >
                  {facet}
                </Chip>
              );
            }
            if (index < 4) {
              return (
                <Chip
                  key={facet}
                  className="text-default-500"
                  size="sm"
                  variant="flat"
                >
                  {`+${variable.facets.length - 3}`}
                </Chip>
              );
            }
            return null;
          })}
        </div>
      );
    case 'description':
      return (
        <div className="text-nowrap text-small capitalize text-default-foreground">
          {cellValue}
        </div>
      );
    case 'data_type':
      return <div className="text-default-foreground">{cellValue}</div>;
    case 'actions':
      return (
        <div className="flex items-center justify-end gap-2">
          <EyeFilledIcon
            className="cursor-pointer text-default-400"
            height={18}
            width={18}
          />
          <EditLinearIcon
            className="cursor-pointer text-default-400"
            height={18}
            width={18}
            onClick={() =>
              navigate({
                to: `/variables/${variable.variable_name}/rollout`,
              })
            }
          />
          <DeleteFilledIcon
            className="cursor-pointer text-default-400"
            height={18}
            width={18}
          />
        </div>
      );
    default:
      return cellValue;
  }
};

export default TableCellRenderer;
