/* eslint-disable no-console */
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
} from '@nextui-org/react';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';

import { type Facet } from '../../../../entities/facets';
import { DataType } from '../../../../enums/datatype.enum';
import { useAddVariable } from '../../../../hooks/api/use-add-variable';
import useFacets from '../../../../hooks/api/use-facets';

const schema = z.object({
  variable_name: z.string().min(1, 'Variable name is required'),
  data_type: z.nativeEnum(DataType, {
    errorMap: () => ({ message: 'Please select a data type' }),
  }),
  description: z.string().min(1, 'Description is required'),
  facets: z.array(z.string()).min(1, 'Facets are required'),
});

type FormData = z.infer<typeof schema>;

type Props = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

const VariableCreateModal = ({ isOpen, onOpenChange }: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      variable_name: '',
      data_type: undefined,
      description: '',
      facets: [],
    },
    mode: 'onChange',
  });

  const { data: facets } = useFacets();

  const addVariableMutation = useAddVariable(() => {
    onClose();
  });

  const onClose = () => {
    reset();
    onOpenChange(false);
  };

  const handleFormSubmit = (data: FormData) => {
    addVariableMutation.mutate(data, {
      // TODO: Remove this after implementing the actual notification
      onSuccess: () => {
        alert('Variable added successfully');
      },
      onError: (error) => {
        console.error('Failed to add variable', error);
        alert('Failed to add variable');
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange} placement="top-center">
      <ModalContent>
        {() => (
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <ModalHeader className="flex flex-col gap-1">
              Add Variable
            </ModalHeader>
            <ModalBody>
              <Controller
                name="variable_name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    autoFocus
                    label="Variable name"
                    placeholder="Enter variable name"
                    variant="bordered"
                    isInvalid={!!errors.variable_name}
                    errorMessage={errors.variable_name?.message}
                  />
                )}
              />
              <Controller
                name="data_type"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    variant="bordered"
                    label="Data type"
                    placeholder="Select data type"
                    isInvalid={!!errors.data_type}
                    errorMessage={errors.data_type?.message}
                  >
                    {Object.values(DataType).map((type: DataType) => (
                      <SelectItem key={type} value={type}>
                        {type}
                      </SelectItem>
                    ))}
                  </Select>
                )}
              />
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    label="Description"
                    placeholder="Enter description"
                    variant="bordered"
                    isInvalid={!!errors.description}
                    errorMessage={errors.description?.message}
                  />
                )}
              />
              <Controller
                name="facets"
                control={control}
                render={({ field: { onChange, value, ...rest } }) => (
                  <Select
                    {...rest}
                    selectedKeys={value}
                    onSelectionChange={(keys) => onChange(Array.from(keys))}
                    variant="bordered"
                    label="Facets"
                    placeholder="Select facets"
                    selectionMode="multiple"
                    isInvalid={!!errors.facets}
                    errorMessage={errors.facets?.message}
                  >
                    {facets?.map((facet: Facet) => (
                      <SelectItem key={facet.facet_id} value={facet.facet_id}>
                        {facet.facet_id}
                      </SelectItem>
                    ))}
                  </Select>
                )}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="flat" onPress={onClose} size="sm">
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                size="sm"
                disabled={isSubmitting}
              >
                Add Variable
              </Button>
            </ModalFooter>
          </form>
        )}
      </ModalContent>
    </Modal>
  );
};

export default VariableCreateModal;
