import { Button,Pagination } from '@nextui-org/react';

type PaginationProps = {
  page: number;
  pages: number;
  onPreviousPage: () => void;
  onNextPage: () => void;
  setPage: (value: number) => void;
}

type Props = {
  paginationProps: PaginationProps;
}

const TableBottomContent = ({ paginationProps }: Props) => {
  const { page, pages, onPreviousPage, onNextPage, setPage } = paginationProps;

  return (
    <div className="flex flex-col items-center justify-between gap-2 px-2 py-2 sm:flex-row">
      <Pagination
        isCompact
        showControls
        showShadow
        color="primary"
        page={page}
        total={pages}
        onChange={setPage}
      />
      <div className="flex items-center justify-end gap-6">
        <div className="flex items-center gap-3">
          <Button
            isDisabled={page === 1}
            size="sm"
            variant="flat"
            onPress={onPreviousPage}
          >
            Previous
          </Button>
          <Button
            isDisabled={page === pages}
            size="sm"
            variant="flat"
            onPress={onNextPage}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TableBottomContent;
