import { Icon } from '@iconify/react/dist/iconify.js';
import { Button, cn, Tooltip } from '@nextui-org/react';

type Props = {
  isCompact: boolean;
  tooltipContent: string;
  icon: string;
  text: string;
}

const SidebarFooterItem = ({
  isCompact,
  tooltipContent,
  icon,
  text,
}: Props) => (
  <Tooltip content={tooltipContent} isDisabled={!isCompact} placement="right">
    <Button
      fullWidth
      className={cn(
        'justify-start truncate text-default-500 data-[hover=true]:text-foreground',
        {
          'justify-center': isCompact,
        },
      )}
      isIconOnly={isCompact}
      startContent={
        isCompact ? null : (
          <Icon className="flex-none text-default-500" icon={icon} width={24} />
        )
      }
      variant="light"
    >
      {isCompact ? (
        <Icon className="text-default-500" icon={icon} width={24} />
      ) : (
        text
      )}
    </Button>
  </Tooltip>
);

export default SidebarFooterItem;
