'use client';

import type { Selection, SortDescriptor } from '@nextui-org/react';
import {
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react';
import type { Key } from 'react';
import { useMemo, useState } from 'react';

import type { Variable } from '../../../../entities/variable';
import useVariables from '../../../../hooks/api/use-variables';
import usePagination from '../../../../hooks/common/use-pagination';
import TableBottomContent from '../TableBottomContent';
import TableCellRenderer from '../TableCellRenderer';
import TableTopContent from '../TableTopContent';
import TopBar from '../TopBar';
import VariableCreateModal from '../VariableCreateModal';

const columns = [
  { name: 'Variable Name', uid: 'variable_name' },
  { name: 'Data Type', uid: 'data_type' },
  { name: 'Description', uid: 'description' },
  { name: 'Facets', uid: 'facets' },
  { name: 'Actions', uid: 'actions' },
];

const VariablesTable = () => {
  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set([]));
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: 'name',
    direction: 'ascending',
  });

  const { data: variables, isFetching } = useVariables();

  const {
    page,
    setPage,
    pages,
    rowsPerPage,
    onNextPage,
    onPreviousPage,
    paginatedItems,
  } = usePagination({
    totalItems: variables?.length || 0,
  });

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const headerColumns = useMemo(
    () =>
      columns.map((item) => {
        if (item.uid === sortDescriptor.column) {
          return {
            ...item,
            sortDirection: sortDescriptor.direction,
          };
        }
        return item;
      }),
    [sortDescriptor],
  );

  const items = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return variables?.slice(start, end) || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatedItems, variables]);

  const sortedItems = useMemo(
    () =>
      [...items].sort((a: Variable, b: Variable) => {
        const col = sortDescriptor.column as keyof Variable;

        const first = a[col];
        const second = b[col];

        const cmp = first < second ? -1 : first > second ? 1 : 0;

        return sortDescriptor.direction === 'descending' ? -cmp : cmp;
      }),
    [sortDescriptor, items],
  );

  if (isFetching)
    return (
      <div className="flex items-center justify-center h-[550px]">
        <Spinner size="lg" label="Loading variables..." />
      </div>
    );

  return (
    <div className="w-full p-6">
      <TopBar onOpenVariableCreateModal={onOpen} />
      <VariableCreateModal isOpen={isOpen} onOpenChange={onOpenChange} />
      <Table
        aria-label="Variables table"
        topContent={
          <TableTopContent
            headerColumns={headerColumns}
            filterSelectedKeys={selectedKeys}
            sortDescriptor={sortDescriptor}
            setSortDescriptor={setSortDescriptor}
          />
        }
        bottomContent={
          <TableBottomContent
            paginationProps={{
              page,
              pages,
              onPreviousPage,
              onNextPage,
              setPage,
            }}
          />
        }
        bottomContentPlacement="outside"
        classNames={{
          wrapper: 'max-h-[550px]',
        }}
        selectedKeys={selectedKeys}
        selectionMode="multiple"
        sortDescriptor={sortDescriptor}
        topContentPlacement="outside"
        onSelectionChange={setSelectedKeys}
        onSortChange={setSortDescriptor}
      >
        <TableHeader columns={headerColumns}>
          {(column) => (
            <TableColumn
              key={column.uid}
              align={column.uid === 'actions' ? 'end' : 'start'}
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={sortedItems}>
          {(item) => (
            <TableRow key={item.variable_name}>
              {(columnKey: Key) => (
                <TableCell>
                  <TableCellRenderer variable={item} columnKey={columnKey} />
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default VariablesTable;
