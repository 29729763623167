import { useQuery } from '@tanstack/react-query';

import { API, type ApiResponse } from '../../app/api';
import { type Facet } from '../../entities/facets';

const useFacets = () =>
  useQuery({
    queryKey: ['facets'],
    queryFn: async (): Promise<Facet[]> => {
      const response = await API.get('/api/v1/facets');
      const facetsApiResponse = response.data as ApiResponse<Facet[]>;
      return facetsApiResponse.data;
    },
    initialData: [],
  });

export default useFacets;
