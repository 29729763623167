import TeamAvatar from '../../../../components/common/TeamAvatar';
import type { SidebarItemProps } from '../Sidebar/Sidebar';

const teamItems: SidebarItemProps[] = [
  {
    key: 'your-teams',
    title: 'Your Teams',
    items: [
      {
        key: 'nextui',
        href: '#',
        title: 'NextUI',
        startContent: <TeamAvatar name="Next UI" />,
      },
      {
        key: 'tailwind-variants',
        href: '#',
        title: 'Tailwind Variants',
        startContent: <TeamAvatar name="Tailwind Variants" />,
      },
      {
        key: 'nextui-pro',
        href: '#',
        title: 'NextUI Pro',
        startContent: <TeamAvatar name="NextUI Pro" />,
      },
    ],
  },
];

export default teamItems;
