import { Icon } from '@iconify/react';
import type {
  Selection} from '@nextui-org/react';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger} from '@nextui-org/react';

type Props = {
  filterSelectedKeys: Selection;
}

const SelectedActions = ({ filterSelectedKeys }: Props) => {
  if (filterSelectedKeys !== 'all' && filterSelectedKeys.size === 0)
    return null;

  return (
    <Dropdown>
      <DropdownTrigger>
        <Button
          className="bg-default-100 text-default-800"
          endContent={
            <Icon
              className="text-default-400"
              icon="solar:alt-arrow-down-linear"
            />
          }
          size="sm"
          variant="flat"
        >
          Selected Actions
        </Button>
      </DropdownTrigger>
      <DropdownMenu aria-label="Selected Actions">
        <DropdownItem key="send-email">Send email</DropdownItem>
        <DropdownItem key="pay-invoices">Pay invoices</DropdownItem>
        <DropdownItem key="bulk-edit">Bulk edit</DropdownItem>
        <DropdownItem key="end-contract">End contract</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default SelectedActions;
