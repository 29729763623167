'use client';

import type { AvatarProps } from '@nextui-org/react';
import { Avatar } from '@nextui-org/react';
import { cn } from '@nextui-org/react';
import React from 'react';

const TeamAvatar = React.forwardRef<HTMLSpanElement, AvatarProps>(
  ({ name, size = 'sm', className, classNames = {}, ...props }, ref) => (
    <Avatar
      {...props}
      ref={ref}
      classNames={{
        ...classNames,
        base: cn(
          'bg-transparent border border-divider',
          classNames?.base,
          className,
        ),
        name: cn(
          'text-default-500 text-[0.6rem] font-semibold',
          classNames?.name,
        ),
      }}
      getInitials={(name) =>
        (name[0] || '') + (name[name.lastIndexOf(' ') + 1] || '').toUpperCase()
      }
      name={name}
      radius="md"
      size={size}
    />
  ),
);

TeamAvatar.displayName = 'TeamAvatar';

export default TeamAvatar;
