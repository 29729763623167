import type { IconSvgProps } from '../../types/common.type';

const HachiumIcon = ({
  width = '40',
  height = '20',
  ...props
}: IconSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 77 36"
    fill="none"
    {...props}
  >
    <path
      d="M0.761414 34.6858V0.857178H7.16141V15.44L5.88141 15.9886C6.21665 14.8915 6.81094 13.901 7.66427 13.0172C8.54808 12.1029 9.59951 11.3715 10.8186 10.8229C12.0376 10.2743 13.3176 10 14.6586 10C16.4871 10 18.0109 10.3657 19.23 11.0972C20.4795 11.8286 21.409 12.941 22.0186 14.4343C22.6586 15.8972 22.9786 17.7105 22.9786 19.8743V34.6858H16.4871V20.3772C16.4871 19.28 16.3347 18.3657 16.03 17.6343C15.7252 16.9029 15.2528 16.3696 14.6128 16.0343C14.0033 15.6991 13.2414 15.5467 12.3271 15.5772C11.5957 15.5772 10.91 15.6991 10.27 15.9429C9.66046 16.1562 9.12713 16.4762 8.66998 16.9029C8.24332 17.3296 7.89284 17.8172 7.61856 18.3657C7.37475 18.9143 7.25284 19.5086 7.25284 20.1486V34.6858H4.05284C3.35189 34.6858 2.72713 34.6858 2.17856 34.6858C1.62999 34.6858 1.1576 34.6858 0.761414 34.6858Z"
      fill="black"
    />
    <path
      d="M39.7132 35.1429C37.397 35.1429 35.3246 34.5943 33.4961 33.4972C31.6675 32.4 30.2199 30.9067 29.1532 29.0172C28.117 27.1277 27.5989 24.9791 27.5989 22.5715C27.5989 20.1638 28.117 18.0153 29.1532 16.1257C30.2199 14.2362 31.6675 12.7429 33.4961 11.6457C35.3246 10.5486 37.397 10 39.7132 10C41.877 10 43.858 10.3962 45.6561 11.1886C47.4541 11.981 48.856 13.0781 49.8618 14.48L46.2961 18.7772C45.8694 18.1981 45.3361 17.68 44.696 17.2229C44.056 16.7353 43.3551 16.3543 42.5932 16.08C41.8618 15.8058 41.0999 15.6686 40.3075 15.6686C39.058 15.6686 37.9456 15.9734 36.9703 16.5829C36.0256 17.1619 35.2789 17.9696 34.7303 19.0058C34.1818 20.0419 33.9075 21.2305 33.9075 22.5715C33.9075 23.8515 34.1818 25.0096 34.7303 26.0458C35.3094 27.0819 36.0865 27.9048 37.0618 28.5143C38.037 29.1238 39.1341 29.4286 40.3532 29.4286C41.1456 29.4286 41.8922 29.3219 42.5932 29.1086C43.2941 28.8953 43.9494 28.56 44.5589 28.1029C45.1989 27.6458 45.778 27.0972 46.2961 26.4572L49.816 30.7543C48.8103 32.0648 47.378 33.1315 45.5189 33.9543C43.6903 34.7467 41.7551 35.1429 39.7132 35.1429Z"
      fill="black"
    />
    <path
      d="M62.2957 35.1429C60.5281 35.1429 59.0043 34.7619 57.7243 34C56.4747 33.2077 55.4995 32.0953 54.7985 30.6629C54.1281 29.2 53.7928 27.4477 53.7928 25.4058V10.5029H60.2843V24.3543C60.2843 25.4515 60.4671 26.4115 60.8328 27.2343C61.1986 28.0267 61.7166 28.6362 62.3871 29.0629C63.0576 29.4896 63.8805 29.7029 64.8557 29.7029C65.5566 29.7029 66.1966 29.5962 66.7757 29.3829C67.3852 29.1391 67.9033 28.8038 68.33 28.3772C68.7871 27.9505 69.1376 27.4477 69.3814 26.8686C69.6252 26.2896 69.7471 25.6648 69.7471 24.9943V10.5029H76.2386V34.6858H70.0671L69.8386 29.7029L71.0271 29.1543C70.6614 30.2819 70.0519 31.3029 69.1985 32.2172C68.3452 33.1315 67.3243 33.8477 66.1357 34.3658C64.9471 34.8839 63.6671 35.1429 62.2957 35.1429Z"
      fill="black"
    />
  </svg>
);

export default HachiumIcon;
