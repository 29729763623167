import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API } from '../../app/api';
import type { Variable } from '../../entities/variable';

type AddVariableContext = {
  previousVariables: Variable[];
};

const addVariable = async (variable: Variable): Promise<Variable> => {
  const response = await API.post<Variable>('/api/v1/variables', variable);
  return response.data;
};

export const useAddVariable = (onAdd?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation<Variable, Error, Variable, AddVariableContext>({
    mutationFn: addVariable,

    onMutate: (newVariable: Variable) => {
      const previousVariables =
        queryClient.getQueryData<Variable[]>(['variables']) || [];

      queryClient.setQueryData<Variable[]>(['variables'], (variables = []) => [
        ...variables,
        newVariable,
      ]);

      onAdd?.();

      return { previousVariables };
    },

    onError: (_error, _newVariable, context) => {
      if (!context) return;

      queryClient.setQueryData<Variable[]>(
        ['variables'],
        context.previousVariables,
      );
    },
  });
};
