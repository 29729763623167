import type { IconSvgProps } from '../../types/common.type';

const HachiumIconText = ({
  width = '200',
  height = '35',
  ...props
}: IconSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 233 40"
    fill="none"
    {...props}
  >
    <path
      d="M0.5 39.4667V0H7.82502V17.0133L6.36002 17.6533C6.74371 16.3733 7.42389 15.2178 8.40056 14.1867C9.41211 13.12 10.6155 12.2667 12.0107 11.6267C13.406 10.9867 14.871 10.6667 16.4058 10.6667C18.4986 10.6667 20.2427 11.0933 21.6379 11.9467C23.068 12.8 24.1319 14.0978 24.8295 15.84C25.562 17.5467 25.9283 19.6622 25.9283 22.1867V39.4667H18.4986V22.7733C18.4986 21.4933 18.3242 20.4267 17.9754 19.5733C17.6266 18.72 17.0859 18.0978 16.3534 17.7067C15.6558 17.3156 14.7838 17.1378 13.7374 17.1733C12.9002 17.1733 12.1154 17.3156 11.3829 17.6C10.6853 17.8489 10.0749 18.2222 9.55163 18.72C9.0633 19.2178 8.66217 19.7867 8.34824 20.4267C8.06919 21.0667 7.92967 21.76 7.92967 22.5067V39.4667H4.26715C3.46489 39.4667 2.74983 39.4667 2.12197 39.4667C1.49411 39.4667 0.953454 39.4667 0.5 39.4667Z"
      fill="black"
    />
    <path
      d="M43.4074 40C41.1052 40 39.0298 39.36 37.1811 38.08C35.3324 36.8 33.8674 35.0578 32.7861 32.8533C31.7048 30.6489 31.1641 28.1244 31.1641 25.28C31.1641 22.4356 31.7048 19.9111 32.7861 17.7067C33.9023 15.5022 35.4022 13.7778 37.2858 12.5333C39.1693 11.2889 41.3145 10.6667 43.7213 10.6667C45.0817 10.6667 46.3199 10.88 47.4361 11.3067C48.5872 11.6978 49.5813 12.2489 50.4185 12.96C51.2905 13.6711 52.023 14.4889 52.616 15.4133C53.209 16.3378 53.6275 17.3333 53.8717 18.4L52.302 18.1333V11.2533H59.6794V39.4667H52.1974V32.6933L53.8717 32.5333C53.5926 33.5289 53.1392 34.4711 52.5113 35.36C51.8835 36.2489 51.0986 37.0489 50.1569 37.76C49.25 38.4356 48.221 38.9867 47.0699 39.4133C45.9188 39.8044 44.698 40 43.4074 40ZM45.4479 33.44C46.8432 33.44 48.064 33.1022 49.1104 32.4267C50.1569 31.7511 50.9591 30.8089 51.5172 29.6C52.1102 28.3556 52.4067 26.9156 52.4067 25.28C52.4067 23.68 52.1102 22.2756 51.5172 21.0667C50.9591 19.8578 50.1569 18.9156 49.1104 18.24C48.064 17.5289 46.8432 17.1733 45.4479 17.1733C44.0876 17.1733 42.8842 17.5289 41.8377 18.24C40.8262 18.9156 40.0239 19.8578 39.4309 21.0667C38.838 22.2756 38.5415 23.68 38.5415 25.28C38.5415 26.9156 38.838 28.3556 39.4309 29.6C40.0239 30.8089 40.8262 31.7511 41.8377 32.4267C42.8842 33.1022 44.0876 33.44 45.4479 33.44Z"
      fill="black"
    />
    <path
      d="M79.1622 40C76.5113 40 74.1394 39.36 72.0465 38.08C69.9536 36.8 68.2968 35.0578 67.0759 32.8533C65.89 30.6489 65.297 28.1422 65.297 25.3333C65.297 22.5244 65.89 20.0178 67.0759 17.8133C68.2968 15.6089 69.9536 13.8667 72.0465 12.5867C74.1394 11.3067 76.5113 10.6667 79.1622 10.6667C81.6388 10.6667 83.9061 11.1289 85.964 12.0533C88.022 12.9778 89.6265 14.2578 90.7776 15.8933L86.6965 20.9067C86.2082 20.2311 85.5978 19.6267 84.8653 19.0933C84.1328 18.5244 83.3305 18.08 82.4585 17.76C81.6213 17.44 80.7493 17.28 79.8424 17.28C78.4123 17.28 77.1391 17.6356 76.0229 18.3467C74.9416 19.0222 74.087 19.9644 73.4592 21.1733C72.8313 22.3822 72.5174 23.7689 72.5174 25.3333C72.5174 26.8267 72.8313 28.1778 73.4592 29.3867C74.1219 30.5956 75.0114 31.5556 76.1276 32.2667C77.2438 32.9778 78.4995 33.3333 79.8947 33.3333C80.8016 33.3333 81.6562 33.2089 82.4585 32.96C83.2608 32.7111 84.0107 32.32 84.7083 31.7867C85.4408 31.2533 86.1036 30.6133 86.6965 29.8667L90.7253 34.88C89.5742 36.4089 87.9348 37.6533 85.8071 38.6133C83.7142 39.5378 81.4993 40 79.1622 40Z"
      fill="black"
    />
    <path
      d="M95.4862 39.4667V0H102.811V17.0133L101.346 17.6533C101.73 16.3733 102.41 15.2178 103.387 14.1867C104.398 13.12 105.602 12.2667 106.997 11.6267C108.392 10.9867 109.857 10.6667 111.392 10.6667C113.485 10.6667 115.229 11.0933 116.624 11.9467C118.054 12.8 119.118 14.0978 119.816 15.84C120.548 17.5467 120.914 19.6622 120.914 22.1867V39.4667H113.485V22.7733C113.485 21.4933 113.31 20.4267 112.962 19.5733C112.613 18.72 112.072 18.0978 111.34 17.7067C110.642 17.3156 109.77 17.1378 108.724 17.1733C107.886 17.1733 107.102 17.3156 106.369 17.6C105.671 17.8489 105.061 18.2222 104.538 18.72C104.049 19.2178 103.648 19.7867 103.334 20.4267C103.055 21.0667 102.916 21.76 102.916 22.5067V39.4667H99.2533C98.451 39.4667 97.736 39.4667 97.1081 39.4667C96.4803 39.4667 95.9396 39.4667 95.4862 39.4667Z"
      fill="black"
    />
    <path d="M128.662 39.4667V11.2533H136.091V39.4667H128.662Z" fill="black" />
    <path
      d="M146.8 40C145.439 40 144.393 39.6089 143.66 38.8267C142.928 38.0444 142.562 36.9244 142.562 35.4667C142.562 34.1511 142.945 33.0844 143.713 32.2667C144.515 31.4133 145.544 30.9867 146.8 30.9867C148.125 30.9867 149.154 31.3778 149.887 32.16C150.619 32.9422 150.985 34.0444 150.985 35.4667C150.985 36.8178 150.602 37.92 149.834 38.7733C149.067 39.5911 148.055 40 146.8 40ZM146.8 19.7867C145.439 19.7867 144.393 19.3956 143.66 18.6133C142.928 17.8311 142.562 16.7111 142.562 15.2533C142.562 13.9378 142.945 12.8711 143.713 12.0533C144.515 11.2 145.544 10.7733 146.8 10.7733C148.125 10.7733 149.154 11.1644 149.887 11.9467C150.619 12.7289 150.985 13.8311 150.985 15.2533C150.985 16.6044 150.602 17.7067 149.834 18.56C149.067 19.3778 148.055 19.7867 146.8 19.7867Z"
      fill="black"
    />
    <path
      d="M166.476 40C164.453 40 162.709 39.5556 161.244 38.6667C159.814 37.7422 158.698 36.4444 157.896 34.7733C157.128 33.0667 156.745 31.0222 156.745 28.64V11.2533H164.174V27.4133C164.174 28.6933 164.383 29.8133 164.802 30.7733C165.221 31.6978 165.814 32.4089 166.581 32.9067C167.348 33.4044 168.29 33.6533 169.406 33.6533C170.209 33.6533 170.941 33.5289 171.604 33.28C172.301 32.9956 172.894 32.6044 173.383 32.1067C173.906 31.6089 174.307 31.0222 174.586 30.3467C174.865 29.6711 175.005 28.9422 175.005 28.16V11.2533H182.434V39.4667H175.371L175.109 33.6533L176.47 33.0133C176.051 34.3289 175.354 35.52 174.377 36.5867C173.4 37.6533 172.232 38.4889 170.871 39.0933C169.511 39.6978 168.046 40 166.476 40Z"
      fill="black"
    />
    <path
      d="M189.858 39.4667V11.2533H197.026L197.183 16.64L196.084 16.8533C196.468 15.9289 196.974 15.0933 197.602 14.3467C198.264 13.6 199.014 12.96 199.851 12.4267C200.688 11.8933 201.578 11.4844 202.52 11.2C203.462 10.88 204.403 10.72 205.345 10.72C206.775 10.72 208.066 10.9511 209.217 11.4133C210.368 11.8756 211.345 12.6044 212.147 13.6C212.949 14.56 213.577 15.84 214.03 17.44L212.879 17.3333L213.246 16.48C213.699 15.6267 214.275 14.8444 214.972 14.1333C215.67 13.4222 216.455 12.8178 217.327 12.32C218.199 11.8222 219.106 11.4311 220.047 11.1467C220.989 10.8622 221.914 10.72 222.821 10.72C224.983 10.72 226.78 11.1644 228.21 12.0533C229.64 12.9067 230.704 14.2044 231.401 15.9467C232.134 17.6889 232.5 19.8222 232.5 22.3467V39.4667H225.07V22.88C225.07 21.6 224.896 20.5511 224.547 19.7333C224.198 18.9156 223.693 18.3111 223.03 17.92C222.367 17.4933 221.512 17.28 220.466 17.28C219.664 17.28 218.914 17.4222 218.216 17.7067C217.553 17.9556 216.961 18.3289 216.437 18.8267C215.949 19.2889 215.565 19.8578 215.286 20.5333C215.007 21.1733 214.868 21.8667 214.868 22.6133V39.4667H207.386V22.8267C207.386 21.6533 207.211 20.6578 206.862 19.84C206.514 18.9867 206.008 18.3467 205.345 17.92C204.682 17.4933 203.863 17.28 202.886 17.28C202.084 17.28 201.334 17.4222 200.636 17.7067C199.973 17.9556 199.398 18.3289 198.91 18.8267C198.421 19.2889 198.038 19.84 197.758 20.48C197.479 21.12 197.34 21.8133 197.34 22.56V39.4667H189.858Z"
      fill="black"
    />
  </svg>
);

export default HachiumIconText;
