import { useQuery } from '@tanstack/react-query';

import { API, type ApiResponse } from '../../app/api';
import { type Variable } from '../../entities/variable';

const useVariables = () =>
  useQuery({
    queryKey: ['variables'],
    queryFn: async (): Promise<Variable[]> => {
      const apiResponse = await API.get('/api/v1/variables');
      const variablesApiResponse = apiResponse.data as ApiResponse<Variable[]>;
      return variablesApiResponse.data;
    },
    initialData: [],
  });

export default useVariables;
