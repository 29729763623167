import { useMemo, useState } from 'react';

import { useMemoizedCallback } from './use-memoized-callback';

type Props = {
  totalItems: number;
  initialPage?: number;
  initialRowsPerPage?: number;
}

const usePagination = ({
  totalItems,
  initialPage = 1,
  initialRowsPerPage = 10,
}: Props) => {
  const [rowsPerPage] = useState(initialRowsPerPage);
  const [page, setPage] = useState(initialPage);

  const pages = Math.ceil(totalItems / rowsPerPage) || 1;

  const onNextPage = useMemoizedCallback(() => {
    if (page < pages) {
      setPage(page + 1);
    }
  });

  const onPreviousPage = useMemoizedCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  });

  const paginatedItems = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return { start, end };
  }, [page, rowsPerPage]);

  return {
    page,
    setPage,
    pages,
    rowsPerPage,
    onNextPage,
    onPreviousPage,
    paginatedItems,
  };
};

export default usePagination;
